<template>
  <div class="main-wrapper">
    <title-bar
      title="PRIVACY POLICY"
      subtitle=""
    />
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 ">
                <div class="about-info">
                  <div class="about-desc">
                   <h2>PRIVACY POLICY</h2>
                   <h6>Last Updates: December 2021</h6>
                   <p>
                       By using the Site, you agree to the terms of this Privacy Statement. If you do not agree with the practices described in this Privacy Statement, please do not provide us with your personal information or interact with the Site. </p>

<p>This Privacy Statement ("Privacy Statement") explains how Thriggle ("us," "our," and "we") uses your information and applies to all who use our Web site, mobile applications, electronic services, social networking sites and when you receive electronic communications from us. </p>
<p>We supply you with two versions; a condensed version for quick review and a more detailed one for specific information. </p>

                   </p>
                   <h5 style="text-decoration:underline;">Our Easy to Read Condensed Version</h5>
                   <h6>What we collect </h6>
                   <ul>
                    <li> We collect general analytics from website traffic to monitor our servers performance</li>
                    <li> We archive user entered data for a short period of time</li>
                    <li> We archive weblogs and records for a longer period of time</li>

                   </ul>
                   <h6>
                       What we share with others 
                   </h6>
                   <ul>
                       <li>	 We do not share your information with 3rd parties</li>
                    <li>	 We do not use cookies or any other tracking devices</li>
                    <li>	 We do not send you solicitations</li>
                    <li>	 We require all users to be a minimum age of 18 years old</li>
                    <li>	 We will share user information deemed to be illegal with law enforcement agencies</li>
                    <li>	 Other parties on Thriggle may provide links to third party websites. We cannot control the privacy policies of these outside parties.</li>
                    <li>	 User information posted by you to Thriggle may be accessible through search engines, crawlers, and other similar programs</li>

                   </ul>
                   <h6>What we keep </h6>
                   <ul>
                       <li>Thriggle does not keep credit card information using SSL technology through Authorize.net</li>       
<li>Information shared with other Thriggle users is at your discretion depending on what you post. Please do NOT post anything you do not want to keep private. </li>

                   </ul>
                   <h2>Notification of changes </h2>
                   <ul>
                       <li> Notice of any changes will be posted here on our Privacy Policy</li>
<li> All material changes will be posted in an obvious area on all Thriggle sites</li>
<li> All material changes will be sent by email notification to the email address currently on file</li>

                   </ul>
                   <h2>
                       Contact Information 
                   </h2>
                   <p>Email:   <a href="mailto: privacy@thriggle.com">privacy@thriggle.com</a> </p>
                   <h2>
                      Use at Your Own Risk </h2><p>
By relying on any information or service made available on Thriggle, YOU do so at your own risk. It is hereby understood and agreed that YOU are solely responsible for any damage or loss YOU may incur that results from your use of any section, part, or service, and/or data, images, videos downloaded from Thriggle

                   </p>
                   <h2>
                       Disclaimer
                   </h2>
                   <p>
                       THE LISTINGS, SITE AND SERVICES, INCLUDING ALL CONTENT, FUNCTIONS, MATERIALS AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE OR THE SERVICES, ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. Thriggle DOES NOT WARRANT THAT THE PRODUCTS, SERVICES OR THE FUNCTIONS, FEATURES OR CONTENT OF THE SITE WILL BE TIMELY, ACCURATE, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. YellowZip MAKES NO WARRANTY THAT THE LISTINGS, SITE OR SERVICES WILL MEET USERS' REQUIREMENTS OR EXPECTATIONS, AND EXPRESSLY DISCLAIMS ANY WARRANTIES OR GUARANTEES THAT BY LISTING ANY PRODUCT OR SERVICE ON THE SITE, THE PRODUCT OR SERVICE WILL BE SOLD. IF YOU ARE DISSATISFIED WITH THE SITE OR ANY SERVICES, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SITE AND THE SERVICES. Thriggle MAKES NO WARRANTY REGARDING ANY PRODUCT OR SERVICE PURCHASED OR OBTAINED THROUGH THE SITE OR ANY TRANSACTIONS ENTERED INTO THROUGH THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Thriggle OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO CERTAIN USERS. YellowZip EXPRESSLY DISCLAIMS ANY ENDORSEMENT OR WARRANTY OF ANY PRODUCT OR SERVICE SOLD ON OR THROUGH THE SERVICES, AND ANY RESPONSIBILITY FOR ANY MISREPRESENTATIONS OR BREACHES COMMITTED BY ANOTHER USER 

                   </p>
                   <h2>
                       Cookies
                   </h2>
                   <p>
                       When you visit a Thriggle Search Page, a cookie that uniquely identifies your browser may be sent to your computer. A "cookie" is a small file containing your preferences that is sent to your browser and sent back by your browser each time your browser accesses our servers. Most browsers are initially set up to accept cookies. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. 

                   </p>
                   <h2>
                       When We Disclose Information
                   </h2>
                   <p>
                       We do not share the information we collect from end-users with third-parties. However, we reserve the right to disclose any information about you if it is required by law, necessary to defend our legal rights, or if we in good faith believe it is appropriate to cooperate in investigations of fraud or other illegal activity in which you may be involved (or are reasonably suspected to be involved), even without a subpoena, warrant or court order

                   </p>
                   <h2>
                       No Responsibility for Third-Party Practices
                   </h2>
                   <p>
                       Certain of our Partners who provide or enable any functionality on the Service may also collect information from you. We are not responsible for the privacy practices of our Partners. In addition, any web sites that you click to from one of our Results Pages may have been developed by third-parties over which we exercise no control. Such web sites may send their own cookies to end-users, collect data, or solicit personally identifiable information from you. We are not responsible for the privacy practices or the content of such web sites, including such web sites' use of any information collected when our end-users click through to such web sites. Even though such information might not identify you personally, we strongly encourage you to become familiar with the privacy practices of those web sites. 

                   </p>
                   <h2>
                       Provisions Applicable to Advertiser Only
                   </h2>
                   <p>
                       Any advertiser who wishes to place advertising on our Service will be asked to provide certain information such as the advertiser's business name, phone number, physical address, e-mail address, service area, website URL, description of the advertiser's business, a contact name, advertising category and/or keyword, and credit card information. Some of this information is personally identifiable

                   </p>
                   <h2>
                       Children's Privacy
                   </h2>
                   <p>
                       Thriggle does not intend that any portion of the Site will be accessed or used by children under the age of eighteen, and such use is prohibited. The Site is designed and intended for adults. By using Thriggle, you represent that you are at least eighteen years old and understand that you must be at least eighteen years old in order to create an account and purchase the goods or services advertised through the Site. We will promptly delete information associated with any account be obtain actual knowledge is associated with a registered user who is not at least eighteen years old. 
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
   created () {
      document.title = "Abuse - Thriggle";
  },
	components: {
		TitleBar: TitleBar
	}
};
</script>
